<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div class="content">
          <div class="base-wrapper">
            <div class="base-info">基本信息</div>
            <div class="user-info">
              <div class="left">
                <div>用户名：{{ $store.getters.getUserInfo.username }}</div>
                <div>用户昵称：{{ $store.getters.getUserInfo.nickname }}</div>
                <div>API_key：{{ $store.getters.getUserInfo.salt }}</div>
                <div>
                  会员等级：<span style="color: orange; font-size: 18px">{{ $store.getters.getUserInfo.group_text }}</span>
                </div>
                <!-- <tab-dialog /> /api/matter/imgsHome-->
              </div>
              <div class="right" v-if="info">
                <!-- <el-avatar>{{$store.getters.getUserInfo.avatar}}</el-avatar> -->
                <el-avatar :src="info.avatar"></el-avatar>
              </div>
            </div>
            <div class="base-info">安全设置</div>
            <!-- 修改密码 -->
            <div class="">
              <!-- 表单 -->
              <el-collapse-transition>
                <div>
                  <!--  -->
                  <el-collapse style="width: 90%" v-model="activeNames">
                    <el-collapse-item title="修改昵称头像" name="0">
                      <el-form :model="infoInput" ref="infoInput">
                        <el-form-item label="昵称" prop="nickname">
                          <el-col :span="5">
                            <el-input v-model="infoInput.nickname" placeholder="请输入用户昵称" clearable> </el-input>
                          </el-col>
                        </el-form-item>
                        <el-form-item>
                          <!-- :on-success="
                                    function(response) {
                                      return imgHandleSuccess(response, scope.row)
                                    } " -->
                        </el-form-item>
                        <el-form-item label="用户头像">
                          <template slot-scope="scope">
                            <el-col :span="5" style="margin-left: 40px">
                              <div>
                                <el-upload class="upload-demo" :action="imgURL" :limit="1" accept=".jpg, .jpeg, .png" :headers="headers" :on-success="handleAvatarSuccess" :show-file-list="false" :before-upload="beforeAvatarUpload" :on-remove="
                                    function (response) {
                                      return imgHandleRemove(response, scope.row)
                                    }
                                  ">
                                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                                  <img v-if="imageUrlFull" :src="imageUrlFull" width="150px" height="150px" class="avatar" />
                                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                  <!-- <el-button size="small" type="primary">点击修改</el-button> -->
                                </el-upload>
                              </div>
                            </el-col>
                          </template>
                        </el-form-item>
                        <el-form-item>
                          <el-button style="margin-left: 40px" @click="infoBtn" type="primary" size="medium">确认修改</el-button>
                        </el-form-item>
                      </el-form>
                    </el-collapse-item>
                    <el-collapse-item title="修改密码" name="1">
                      <div>
                        <el-form :model="passwordForm" label-width="8.33333333%" :rules="passwordRules" ref="passwordForm">
                          <!-- <el-form-item label="原密码:" prop="originalPassword">
                            <el-col :span="5">
                              <el-input v-model="passwordForm.originalPassword" placeholder="原密码"></el-input>
                            </el-col>
                          </el-form-item> -->
                          <el-form-item label="新密码:" prop="newPassword">
                            <el-col :span="5">
                              <el-input :type="pass1" v-model="passwordForm.newPassword" placeholder="至少六位密码，区分大小写">
                                <i class="input-icon" slot="suffix" :class="icon1" @click="showPassOne"></i>
                              </el-input>
                            </el-col>
                          </el-form-item>
                          <el-form-item label="确认密码:" prop="confirmPassword">
                            <el-col :span="5">
                              <el-input :type="pass2" v-model="passwordForm.confirmPassword" placeholder="至少六位密码，区分大小写">
                                <i class="input-icon" slot="suffix" :class="icon2" @click="showPassTwo"></i>
                              </el-input>
                            </el-col>
                          </el-form-item>
                          <el-form-item>
                            <el-button @click="passwordBtn" type="primary" size="medium">确认修改</el-button>
                          </el-form-item>
                        </el-form>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item title="修改手机号" name="2">
                      <div>
                        <div class="left">已绑定手机：{{ hideCenterPhone($store.getters.getUserInfo.username) }}</div>
                        <br />
                        <!-- 表单 -->
                        <el-collapse-transition>
                          <div class="phone-form">
                            <el-form :model="phoneForm" label-width="10%" :rules="phoneRules" ref="phoneForm">
                              <!-- <el-form-item label="原手机号码:" prop="originalPhone">
                                <el-col :span="5">
                                  <el-input v-model="phoneForm.originalPhone" disabled placeholder="原手机号码"></el-input>
                                </el-col>
                              </el-form-item>
                              <el-form-item label="验证码:" prop="originalPhoneCode">
                                <el-col :span="5" class="flex">
                                  <el-input class="phone-code" v-model="phoneForm.originalPhoneCode" placeholder="手机验证码"></el-input>
                                  <el-button @click="getCode1" :disabled="!codeFlag1" class="btn-code">{{ codeBtnText1 }}</el-button>
                                </el-col>
                              </el-form-item> @click="obj.validate()"-->
                              <el-form-item label="新手机号码:" prop="newPhone">
                                <el-col :span="5">
                                  <el-input v-model="phoneForm.newPhone" placeholder="新手机号" @blur="blur($event)"></el-input>
                                </el-col>
                              </el-form-item>
                              <el-form-item label="验证码:" prop="newPhoneCode">
                                <el-col :span="5" class="flex">
                                  <el-input class="phone-code" v-model="phoneForm.newPhoneCode" placeholder="手机验证码"></el-input>
                                  <el-button @click="verfiy" :disabled="!codeFlag2" class="btn-code">{{ codeBtnText2 }}</el-button>
                                </el-col>
                              </el-form-item>
                              <el-form-item>
                                <el-button @click="phoneBtn" type="primary" size="medium">确认修改</el-button>
                              </el-form-item>
                            </el-form>
                          </div>
                        </el-collapse-transition>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item title="修改支付宝提现账号" name="3">
                      <div class="left">已绑定用户：{{ name }} ------ {{ accountzi }}</div>
                      <br />
                      <div>
                        <!-- 表单 -->
                        <el-collapse-transition>
                          <div>
                            <el-form :model="aiPayForm" label-width="10%" :rules="aipayRules" ref="aipayForm">
                              <el-form-item label="支付宝账号:" prop="aiPayNumber">
                                <el-col :span="5">
                                  <el-input v-model="aiPayForm.aiPayNumber" placeholder="请输入支付宝账号"></el-input>
                                </el-col>
                              </el-form-item>
                              <el-form-item label="真实姓名:" prop="aiPayName">
                                <el-col :span="5">
                                  <el-input v-model="aiPayForm.aiPayName" placeholder="真实姓名"></el-input>
                                </el-col>
                              </el-form-item>
                              <el-form-item>
                                <el-button @click="aiPayBtn" type="primary" size="medium">确认修改</el-button>
                              </el-form-item>
                            </el-form>
                          </div>
                        </el-collapse-transition>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </el-collapse-transition>
            </div>
            <!-- 修改手机号码 -->
            <!-- 修改提现支付宝账号 -->
          </div>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
// import TabDialog from 'components/table/TabDialog'
import { resetpwd, resetzfb, checkCap, changemobile, getInfoNick, getUserMoney, getSitepassword } from 'network/api'
import { hideCenterPhone, hideStartName } from 'utils/utils'
// import TableDialog from '../../../components/table/TabDialog.vue'

export default {
  inject: ['reload'],
  components: {
    AppMain
    // TabDialog
    // eslint-disable-next-line vue/no-unused-components
    // TableDialog
  },
  data() {
    // var checkNickName = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入用户昵称'))
    //   } else {
    //     return callback()
    //   }
    // }
    // 原始密码
    // var validatePass = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error('请输入原密码'))
    //   } else {
    //     callback()
    //   }
    // }
    // 新密码
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入新密码'))
      } else {
        if (this.passwordForm.confirmPassword !== '') {
          this.$refs.passwordForm.validateField('confirmPassword')
        }
        if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(value)) {
          callback(new Error('由数字和字母组成,长度在6到16位之间'))
        }
        callback()
      }
    }
    // 确认密码
    var validatePass3 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('由数字和字母组成,长度在6到16位之间'))
      } else if (value !== this.passwordForm.newPassword) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    // 原手机号码
    var validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码'))
      } else if (!/^1[3-9]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    // 原手机号码验证码
    var validatePhoneCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入验证码'))
      } else {
        callback()
      }
    }
    // 新手机号码
    var validateNewPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码'))
      } else if (!/^1[3-9]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    // 新手机号码验证码
    var validateNewPhoneCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入验证码'))
      } else {
        callback()
      }
    }
    // 支付宝账号验证
    var validatorAipayNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入正确的支付宝账号'))
      } else {
        callback()
      }
    }
    // 支付宝真实姓名验证
    var validatorAipayName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入真实姓名'))
      } else if (!/^[\u4e00-\u9fa5]+$/.test(value)) {
        callback(new Error('请输入正确的姓名'))
      } else {
        callback()
      }
    }
    return {
      accountzi: 'null', // 绑定的支付账号
      name: 'xx',
      imageUrl: '',
      imageUrlFull: '',
      url: '',
      imgURL: this.$url + '/api/common/upload',
      activeNames: ['4'],
      trueName: '',
      // showPasswordForm: true,
      // showPhoneForm: true,
      // showAipayForm: true,
      infoInput: {
        nickname: '',
        avatar: ''
      },
      obj: '',
      info: '',
      passwordForm: {
        // originalPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      phoneForm: {
        originalPhone: '',
        originalPhoneCode: '',
        newPhone: '',
        newPhoneCode: ''
      },
      aiPayForm: {
        aiPayNumber: '',
        aiPayName: ''
      },
      icon1: 'el-icon-view', // 改变图标
      pass1: 'password', // 改变input类型
      icon2: 'el-icon-view', // 改变图标
      pass2: 'password', // 改变input类型
      timer1: null,
      codeFlag1: true,
      codeBtnText1: '获取验证码',
      timer2: null,
      codeFlag2: true,
      codeBtnText2: '获取验证码',
      // 密码表单验证规则
      passwordRules: {
        // originalPassword: [{ validator: validatePass, trigger: 'blur' }],
        newPassword: [{ validator: validatePass2, trigger: 'blur' }],
        confirmPassword: [{ validator: validatePass3, trigger: 'blur' }]
      },
      // 手机号码验证规则
      phoneRules: {
        originalPhone: [{ validator: validatePhone, trigger: 'blur' }],
        originalPhoneCode: [{ validator: validatePhoneCode, trigger: 'blur' }],
        newPhone: [{ validator: validateNewPhone, trigger: 'blur' }],
        newPhoneCode: [{ validator: validateNewPhoneCode, trigger: 'blur' }]
      },
      // 修改支付宝验证规则
      aipayRules: {
        aiPayNumber: [{ validator: validatorAipayNumber, trigger: 'blur' }],
        aiPayName: [{ validator: validatorAipayName, trigger: 'blur' }]
      }
    }
  },
  computed: {
    hideCenterPhone() {
      return hideCenterPhone
    },
    hideStartName() {
      return hideStartName
    },
    // 自定义请求头
    headers() {
      return {
        Token: this.$store.getters.getUserInfo.token
      }
    }
  },
  created() {
    this.phoneForm.originalPhone = this.$store.getters.getUserInfo.username
    // console.log(this.$store.getters.getUserInfo)
    getUserMoney().then((res) => {
      if (res.code !== 1) {
        return this.$message.warning(res.msg)
      }
      if (res.code === 1) {
        this.info = res.data
        this.trueName = res.data.true_name
        this.accountzi = res.data.ali_pay
        this.name = res.data.true_name
      }
    })
    this.url = this.$url
    // this.load()
  },
  methods: {
    blur() {
      const mobile = this.phoneForm.newPhone
      localStorage.setItem('mobile', mobile)
    },
    handleAvatarSuccess(res, file) {
      this.imageUrlFull = file.response.data.fullurl
      this.imageUrl = file.response.data.url
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/png' || 'image/jpg' || 'image/jpeg'
      const isLt1M = file.size / 1024 / 1024 < 1
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传头像图片大小不能超过 1MB!')
      }
      return isJPG && isLt1M
    },
    handleDelete() {
      this.deleteModel = true
    },
    // 监听图片上传成功
    imgHandleSuccess(res, item) {
      if (res.code !== 1) {
        return this.$message({
          message: '图片上传失败，请重试',
          type: 'error',
          offset: 80
        })
      } else {
        this.$message({
          message: '图片上传成功，如要重新上传，请先移除当前图片！',
          type: 'success',
          offset: 80
        })
      }
    },
    // 图片的操作-0----------------
    imgHandleRemove(file, item) {
      this.$message({
        message: '你移除了图片',
        type: 'info',
        offset: 80
      })
    },
    // 修改用户昵称头像 // 效验规则是否成功
    infoBtn() {
      this.$refs.infoInput.validate((valid) => {
        if (valid) {
          if (this.infoInput.nickname.trim() === '' && this.imageUrl.trim() === '') {
            this.$message({
              message: '昵称或头像不能为空',
              type: 'warning'
            })
            return false
          }
          // 修改昵称
          getInfoNick(this.infoInput.nickname, this.imageUrl).then((res) => {
            // 修改失败
            if (res.code !== 1) {
              return this.$message.error(res.msg)
            }
            // 修改成功
            if (res.code === 1) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
            }
            if (this.infoInput.nickname) {
              this.$store.getters.getUserInfo.nickname = this.infoInput.nickname
              this.infoInput.nickname = ''
            }
            // getUserMoney().then(res => {
            //   console.log(res)
            //   const userInfo = res.data.userinfo
            //   this.$store.dispatch('login', userInfo)
            // })
            // this.$store.getters.getUserInfo.avatar = window.localStorage.getItem('userInfo')
            this.imageUrl = ''
            this.infoInput.nickname = ''
            this.refresh()
          })
        } else {
          return false
        }
      })
    },
    // 确认修改密码
    passwordBtn() {
      // 效验规则是否成功
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          // 修改密码
          resetpwd(this.passwordForm.newPassword).then((res) => {
            // 修改失败
            if (res.code !== 1) {
              this.$message.error(res.msg)
            }
            // 修改成功
            if (res.code === 1) {
              this.$message({ message: res.msg, type: 'success' })
              // 重新登录
              const _this = this
              setTimeout(function () {
                _this.$router.push('/login')
              }, 1500)
            }
          })
        } else {
          return false
        }
      })
    },
    // 获取原手机号验证码
    // getCode1() {
    //   let time = 60
    //   if (!this.timer1) {
    //     this.timer1 = setInterval(() => {
    //       if (time > 0) {
    //         this.codeFlag1 = false
    //         this.codeBtnText1 = time + 's后重新发送'
    //         time--
    //       } else {
    //         clearInterval(this.timer1)
    //         this.codeFlag1 = true
    //         this.codeBtnText1 = '获取验证码'
    //         this.timer1 = null
    //       }
    //     }, 1000)
    //   }
    //   // 获取验证码
    //   getCode(this.phoneForm.originalPhone, 'changemobile').then((res) => {
    //     this.$message(res.msg)
    //   })
    // },
    // 获取新手机号验证码
    getCode2() {
      let time = 60
      if (!this.timer2) {
        this.timer2 = setInterval(() => {
          if (time > 0) {
            this.codeFlag2 = false
            this.codeBtnText2 = time + 's后重新发送'
            time--
          } else {
            clearInterval(this.timer2)
            this.codeFlag2 = true
            this.codeBtnText2 = '获取验证码'
            this.timer2 = null
          }
        }, 1000)
      }
    },
    // 确认修改手机号码
    phoneBtn() {
      // 效验规则是否成功
      this.$refs.phoneForm.validate((valid) => {
        if (valid) {
          // 成功
          changemobile(this.phoneForm.newPhone, this.phoneForm.newPhoneCode).then((res) => {
            if (res.code !== 1) {
              return this.$message(res.msg)
            }
            if (res.code === 1) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
              // 重新登录
              const _this = this
              setTimeout(function () {
                _this.$router.push('/login')
              }, 1500)
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    verfiy() {
      // load() {
      const _this = this
      getSitepassword().then((res) => {
        const mobile = localStorage.getItem('mobile')
        const pathname = window.location.pathname
        // eslint-disable-next-line eqeqeq
        checkCap(mobile, pathname === '/account/base' ? 'changemobile' : 'changemobile')
          .then((res) => {
            if (res.code === 1) {
              _this.$notify.success({ title: '短信发送成功', message: res.msg })
              _this.getCode2()
            } else {
              _this.$message({ message: res.msg, type: 'error' })
            }
          })
          .catch((err) => {
            console.log(err)
          })
        // 验证成功进行后续操作 checkCap
      })

      // }
    },
    // 修改支付宝账号
    aiPayBtn() {
      this.$refs.aipayForm.validate((valid) => {
        if (valid) {
          // console.log(this.aiPayForm.aiPayName)
          // if (this.trueName === this.aiPayForm.aiPayName) {
          resetzfb(this.aiPayForm.aiPayNumber, this.aiPayForm.aiPayName).then((res) => {
            // 修改失败
            if (res.code !== 1) {
              this.$message.error(res.msg)
            }
            // 修改成功
            if (res.code === 1) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
              // 刷新页面
              this.refresh()
            }
          })
          // }
          // else {
          //   this.$message.error('请输入正确名字')
          // }
        } else {
          return false
        }
      })
    },
    refresh() {
      this.reload()
    },
    // 密码显示1
    showPassOne() {
      if (this.pass1 === 'password') {
        this.pass1 = 'text'
        this.icon1 = 'el-icon-loading'
      } else {
        this.pass1 = 'password'
        this.icon1 = 'el-icon-view'
      }
    },
    // 密码显示2
    showPassTwo() {
      if (this.pass2 === 'password') {
        this.pass2 = 'text'
        this.icon2 = 'el-icon-loading'
      } else {
        this.pass2 = 'password'
        this.icon2 = 'el-icon-view'
      }
    }
  }
}
</script>

<style scoped src="styles/views/account/base.css"></style>
<style lang="scss" scoped>
//顶部图片
.el-avatar {
  width: 150px;
  height: 150px;
}
.el-avatar--circle {
  border-radius: 10%;
}
// .top-img {
//   img {
//   }
// }
//顶部栏
.top {
  width: 95%;
  margin-top: 20px;
  display: flex;
  .cart-one {
    margin-left: 20px;
  }
  .cart-two {
    position: relative;
    .img-box {
      position: absolute;
      top: 60px;
      left: 340px;

      .img {
        width: 100px;
        height: 100px;
      }
      .img-text {
        color: gray;
        margin-left: 5px;
      }
    }
  }
  .cart-three {
    margin-left: 15px;
    .notice-item-one {
      padding-bottom: 11px;
    }

    .notice-item {
      padding-bottom: 5px;
    }

    img {
      width: 15px;
      height: 15px;
    }
  }
}

/* 中间栏 */
.center {
  display: flex;
  margin-left: 20px;
}
.center-left {
  display: flex;
  background-color: blue;
}
.center-right {
  width: 35%;
  height: 100%;
  margin: 21px 0px 0px 15px;
  background-color: white;
  border-radius: 10px;
  .el-input__inner {
    margin: 8px 15px 0px 15px;
    width: 450px;
    height: 30px;
  }
  .btn {
    margin: 8px 15px 0px 15px;
  }
  .text {
    margin: 5px 15px 5px 15px;
  }
}
//渐变框
.liuc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1200px;
  height: 88px;
  background: linear-gradient(308deg, #125adb, #60abfd);
  border-radius: 4px;
  box-sizing: border-box;
  margin: 25px 0;
  color: #fff;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 22px 0;
    flex: 1;
    cursor: pointer;
    transition: all 0.2s;
    margin: 25px 0;

    .item_in {
      width: 280px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid hsla(0, 0%, 100%, 0.65);
      box-sizing: border-box;

      p {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.45);
        font-weight: bold;
      }
    }

    &:hover {
      cursor: pointer;
      background: #0002;
    }
  }

  img {
    // border-radius: 4px;
    width: 25px;
    height: 25px;
  }
}
//底部栏
.bottom {
  margin: 20px 0px 0px 20px;
}
.bottom-content {
  height: 80px;
  background: #fff;
  box-shadow: 0 2px 20px rgba(15, 41, 131, 0.1);
  margin-bottom: 24px;
  padding: 32px 0;
  display: flex;
}
.bottom-item {
  color: gray;
  font-size: 14px;
  padding-left: 40px;
  width: 200px;
  border-right: 1px solid #f0f0f0;
  .font {
    color: black;
  }
  span {
    color: black;
    font-size: 28px;
  }
}
/* 卡片组件自带样式 */
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 15px;
}
.clearfix {
  display: flex;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}
/* 自定义卡片样式 */
.el-card__header {
  border-bottom: none;
}
.el-card__body {
  height: 190px;
  padding-top: 0px;
  padding-left: 40px;
}
.el-card,
.el-message {
  border-radius: 0px;
}
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}
.el-card {
  border: none;
  width: 560px;
}
.title-oblong {
  display: block;
  margin-right: 5px;
  color: #1a91ff;
  height: 20px;
  width: 6px;
  background-color: #1a91ff;
}
/* 充值Dialog */
.content-dialog {
  padding-left: 58px;
  padding-right: 58px;
}

.content-dialog .name {
  margin-bottom: 1em;
}

.content-dialog .price {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.content-dialog .price .packing {
  display: flex;
}

.content-dialog .price .packing .packing-item {
  cursor: pointer;
  width: 80px;
  height: 32px;
  border: 1px solid #d9d9d9;
  margin-right: 16px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-dialog .price .packing .active {
  position: relative;
  border: 1px solid #1890ff;
}

.content-dialog .price .packing .packing-item.active .dagou {
  display: block;
}

.content-dialog .price .packing .packing-item .dagou {
  position: absolute;
  right: -1px;
  bottom: -1px;
  display: none;
}

.input-num {
  margin-left: 70px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.input-num .queding {
  color: #1890ff;
  cursor: pointer;
  padding: 0 8px;
}

.payway-btn {
  display: flex;
  align-items: center;
}

.payway-btn span {
  height: 20px;
}
.way {
  display: flex;
}

.payway {
  cursor: pointer;
  margin-right: 16px;
  width: 120px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
}

.payway .yue {
  margin: 0 8px;
}

.payway .dagou {
  display: none;
}

.payway.active {
  border: 1px solid #1890ff;
}
.phone-code .el-input__inner {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.btn-code.el-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
